import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/core/Layout";
import { Container } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import PageTitle from "../components/core/PageTitle";
import { format } from "date-fns";

interface LegalPageProps extends RouteComponentProps {
  pageContext: {
    data: any;
  };
}

export default function LegalPage(props: LegalPageProps) {
  const { frontmatter, body } = props.pageContext.data;
  const pageUrl = "https://www.jetpackcompose.app" + frontmatter.slug;

  const formattedDate = frontmatter.date
    ? format(new Date(frontmatter.date), "MMMM dd, yyyy")
    : null;

  return (
    <Layout
      maxWidth="lg"
      pageTitle={`${frontmatter.title} - JetpackCompose.app`}
      pageDescription={frontmatter.description}
      pageSlug={frontmatter.slug}
      pageType="legal"
      structuredData={{
        "@context": "https://schema.org",
        "@type": "WebPage",
        name: `${frontmatter.title} - JetpackCompose.app`,
        description: frontmatter.description,
        url: `https://www.jetpackcompose.app${frontmatter.slug}`,
        publisher: {
          "@type": "Organization",
          name: "JetpackCompose.app",
          logo: {
            "@type": "ImageObject",
            url: "https://www.jetpackcompose.app/logo.png",
          },
        },
        mainContentOfPage: {
          "@type": "WebPageElement",
          speakable: {
            "@type": "SpeakableSpecification",
            cssSelector: ["h1", "h2", "p"],
          },
        },
        inLanguage: "en-US",
        isPartOf: {
          "@type": "WebSite",
          url: "https://www.jetpackcompose.app/",
          name: "JetpackCompose.app",
          description: "The #1 destination for all things Jetpack Compose",
        },
      }}
    >
      <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:px-8 pb-16">
        <PageTitle
          header={frontmatter.title}
          subheader={`Last Updated: ${formattedDate}`}
        />

        <div className="mt-8 prose prose-indigo prose-lg text-gray-800 mx-auto">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </div>
    </Layout>
  );
}
